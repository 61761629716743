


















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudyUnitInformation from '@/components/organisms/StudyUnitInformation.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import { StudyHistory } from '@/models/api/history/studyUnits'
import StudyUnitWebApi from '@/mixins/history/StudyUnitWebApi'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    ButtonBase,
    SidebarSwitcher,
    StudyUnitInformation,
    StudentBottomPanel,
  },
})
export default class StudyUnit extends Mixins(StudyUnitWebApi, ClassModeChangeAble) {
  // 定期テストモードではterm_exam_id、入試モードではentrance_exam_information_id、それ以外はcurriculum_s_unit_id
  @Prop()
  id!: number

  private title = ''

  @Prop()
  learningType!: string

  // cookieのclassMode取得
  public classMode() {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private get studentUserId() {
    return Vue.prototype.$cookies.get('authGdls').currentApiUserId
  }

  private studyUnitDatas: StudyHistory[] = []

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadStudyHistories()
    Vue.prototype.$loading.complete()
  }

  private async loadStudyHistories() {
    const params = {
      userId: this.studentUserId,
      classModeCode: this.classMode(),
    }

    // 同じIDを用いるのですが、意味合いが異なるためキー名は分離しています
    switch (this.classMode()) {
      case 'TK':
        params['termExamId'] = this.id
        break
      case 'NY':
        params['resultDrillEntranceExamInformationId'] = this.id
        break
      default:
        params['curriculumSUnitId'] = this.id
        break
    }

    const res = await this.loadStudyHistoriesApi(params)

    this.title = res.title
    this.studyUnitDatas = res.studyHistories
  }

  private async movePrev() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const selectedSubject = this.$route.query.selectedSubject ? String(this.$route.query.selectedSubject) : ''

    if (selectedSubject !== '') {
      this.$router.push({ name: name, query: { selectedSubject: selectedSubject } })
    } else {
      this.$router.push({ name: name })
    }
  }
}
